import { Route, useRouteMatch, useLocation } from "react-router-dom";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";

import classes from "./InformationsContent.module.css";

import MotionCard from "../UI/Card";
import InformationList from "./InformationList";
import SingleInformation from "./SingleInformation";
import { informations } from "../../constants/informationsData";

const InformationsContent = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const splittedLocationArray = location.pathname.split("/");
  const locationPathname = splittedLocationArray[1];

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        className={classes.wrapper}
      >
        <LayoutGroup id={path}>
          <MotionCard
            key={locationPathname}
            layout
            className={classes.infoListCard}
          >
            <InformationList informations={informations} />
          </MotionCard>
          <Route
            path={`${path}/:infoId`}
            render={({ match }) => (
              <AnimatePresence mode="wait">
                <MotionCard
                  key={match.params.infoId}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  className={classes.singleInfoCard}
                >
                  <SingleInformation informations={informations} />
                </MotionCard>
              </AnimatePresence>
            )}
          />
        </LayoutGroup>
      </motion.div>
    </AnimatePresence>
  );
};

export default InformationsContent;
