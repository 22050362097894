import { Link } from "react-router-dom";

import classes from "./InfoList.module.css";

const InfoList = () => {
  return (
    <section className={classes.section}>
      <article>
        <h3>Важни телефони:</h3>
        <div className={classes["phone-link"]}>
          <p>Хитна помоћ:</p>
          <a href="tel:194">194</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>Ватрогасци:</p>
          <a href="tel:193">193</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>Полиција:</p>
          <a href="tel:192">192</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>ПУ Н.Београд:</p>
          <a href="tel:+381113008140">011 300 8140</a>
        </div>
      </article>
      <article>
        <Link
          className={classes["header-link"]}
          to={{ pathname: "http://www.beoelektrane.rs/" }}
          target="_blank"
        >
          ЈКП Београдске електране
        </Link>
        <div className={classes["phone-link"]}>
          <p>Рекламације (Сервисни центар града Београда):</p>
          <a href="tel:11011">11011</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>Обједињена телефонска централа:</p>
          <a href="tel:+381112093000">011 2093 000</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>Бесплатан број телефона:</p>
          <a href="tel:0800110011">0800 110 011</a>
        </div>
      </article>
      <article>
        <Link
          className={classes["header-link"]}
          to={{ pathname: "https://www.bvk.rs/" }}
          target="_blank"
        >
          ЈКП Београдски водовод и канализација
        </Link>
        <div className={classes["phone-link"]}>
          <p>Рекламације (Сервисни центар града Београда):</p>
          <a href="tel:11011">11011</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>Бесплатан број телефона:</p>
          <a href="tel:0800110011">0800 110 011</a>
        </div>
      </article>
      <article>
        <Link
          className={classes["header-link"]}
          to={{ pathname: "http://elektrodistribucija.rs/" }}
          target="_blank"
        >
          ЕПС Дистрибуција Београд
        </Link>
        <div className={classes["phone-link"]}>
          <p>Пријава кварова на мрежи:</p>
          <a href="tel:+381113957799">011 395 77 99</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>Пријава очитаних стања бројила:</p>
          <a href="tel:+381113655414">011 3655 414</a>
        </div>
        <div className={classes["phone-link"]}>
          <p>Пријава неовлашћене потрошње:</p>
          <a href="tel:+381112439333">011 2439 333</a>
        </div>
      </article>
      <article>
        <h3>ИКТ оператери</h3>
        <div className={classes["operator-div"]}>
          <Link
            className={classes["header-link"]}
            to={{ pathname: "https://mts.rs/" }}
            target="_blank"
          >
            МТС
          </Link>
          <div className={classes["phone-link"]}>
            <p>Кориснички центар:</p>
            <a href="tel:0800100100">0800 100 100</a>
          </div>
          <div className={classes["phone-link"]}>
            <p>Телепродаја:</p>
            <a href="tel:0800100150">0800 100 150</a>
          </div>
        </div>
        <div className={classes["operator-div"]}>
          <Link
            className={classes["header-link"]}
            to={{ pathname: "https://sbb.rs/" }}
            target="_blank"
          >
            СББ
          </Link>
          <div className={classes["phone-link"]}>
            <p>Кориснички центар:</p>
            <a href="tel:19900">19900</a>
          </div>
        </div>
        <div className={classes["operator-div"]}>
          <Link
            className={classes["header-link"]}
            to={{ pathname: "https://oriontelekom.rs/" }}
            target="_blank"
          >
            Орион Телеком
          </Link>
          <div className={classes["phone-link"]}>
            <p>Кориснички центар:</p>
            <a href="tel:+381114100100">011 4 100 100</a>
          </div>
          <div className={classes["phone-link"]}>
            <p>Телепродаја:</p>
            <a href="tel:+381114100200">011 4 100 200</a>
          </div>
        </div>
      </article>
    </section>
  );
};

export default InfoList;
