import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Layout from "./components/Layout/Layout";

import HomePage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import Informations from "./pages/Informations";
import UsefulInfo from "./pages/UsefulInfo";
import Gallery from "./pages/Gallery";
import Assembly from "./pages/Assembly";
import Documents from "./pages/Documents";
import NotFound from "./pages/NotFound";
import { routes } from "./routes/routes";

function App() {
  const location = useLocation();

  const splittedPathnameArray = location.pathname.split("/");
  const splittedPathname = splittedPathnameArray[1];

  return (
    <Layout>
      <AnimatePresence mode="wait">
        <Switch location={location} key={splittedPathname}>
          <Route path="/" exact>
            <Redirect to={`/${routes.home}`} />
          </Route>
          <Route path={`/${routes.home}`}>
            <HomePage />
          </Route>
          <Route path={`/${routes.aboutUs}`}>
            <AboutUs />
          </Route>
          <Route path={`/${routes.announcements}`}>
            <Informations />
          </Route>
          <Route path={`/${routes.assembly}`}>
            <Assembly />
          </Route>
          <Route path={`/${routes.documents}`}>
            <Documents />
          </Route>
          <Route path={`/${routes.usefulInfo}`}>
            <UsefulInfo />
          </Route>
          <Route path={`/${routes.gallery}`}>
            <Gallery />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </AnimatePresence>
    </Layout>
  );
}

export default App;
