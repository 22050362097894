import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import classes from "./SingleInformation.module.css";

const SingleInformation = (props) => {
  const { informations } = props;
  const params = useParams();

  const [singleInfoTitleContent, setSingleInfoTitleContent] = useState(null);
  const [singleInfoTextContent, setSingleInfoTextContent] = useState(null);

  useEffect(() => {
    const singleInfo = informations?.find(
      (info) => info.id === Number(params.infoId)
    );

    if (!singleInfo) return;

    setSingleInfoTitleContent(singleInfo.title);

    const parseTextWithLinksAndBold = (text) => {
      const linkRegex = /<link to='(.*?)'>(.*?)<\/link>/g;
      const boldRegex = /<b>(.*?)<\/b>/g;
      const segments = [];
      let lastIndex = 0;
      let match;

      while ((match = linkRegex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          segments.push(text.substring(lastIndex, match.index));
        }
        if (
          match[1].endsWith(".pdf") ||
          match[1].endsWith(".jpg") ||
          match[1].endsWith(".jpeg")
        ) {
          segments.push(
            <a
              key={match[1]}
              href={match[1]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {match[2]}
            </a>
          );
        } else {
          segments.push(
            <Link key={match[1]} to={match[1]}>
              {match[2]}
            </Link>
          );
        }

        lastIndex = linkRegex.lastIndex;
      }

      if (lastIndex < text.length) {
        segments.push(text.substring(lastIndex));
      }

      const finalSegments = segments.map((segment) => {
        if (typeof segment === "string") {
          const boldSegments = [];
          let boldLastIndex = 0;
          let boldMatch;

          while ((boldMatch = boldRegex.exec(segment)) !== null) {
            if (boldMatch.index > boldLastIndex) {
              boldSegments.push(
                segment.substring(boldLastIndex, boldMatch.index)
              );
            }

            boldSegments.push(<b key={boldMatch[1]}>{boldMatch[1]}</b>);
            boldLastIndex = boldRegex.lastIndex;
          }

          if (boldLastIndex < segment.length) {
            boldSegments.push(segment.substring(boldLastIndex));
          }

          return boldSegments;
        }

        return segment;
      });

      return finalSegments.flat();
    };

    const formattedInfoText = singleInfo.text
      .split("<br>")
      .map((line, idx) => <p key={idx}>{parseTextWithLinksAndBold(line)}</p>);

    setSingleInfoTextContent(formattedInfoText);
  }, [informations, params.infoId]);

  return (
    <article className={classes.article}>
      <h1>{singleInfoTitleContent}</h1>
      <div>{singleInfoTextContent}</div>
    </article>
  );
};

export default SingleInformation;
