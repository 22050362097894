import Zapisnik_31_01_2024 from "../assets/Zapisnik_31.01.2024.pdf";
import Poziv_na_prvu_sednicu_skupstine_stanara_17_03_2025 from "../assets/Poziv_na_prvu_sednicu_skupstine_stanara_17_03_2025.pdf";
import Poziv_na_prvu_ponovljenu_sednicu_skupstine_stanara_24_03_2025 from "../assets/Poziv_na_prvu_ponovljenu_sednicu_skupstine_stanara_24_03_2025.pdf";
import Zapisnik_sa_I_skupstine_SZ_17_03_2025 from "../assets/Zapisnik_sa_I_skupstine_SZ_17_03_2025.pdf";

export const assemblyList = [
  {
    id: 1,
    name: "31.01.2024. Записник са прве поновљене скупштине стамбене заједнице Јурија Гагарина 122 у 2024. години",
    document: Zapisnik_31_01_2024,
  },
  {
    id: 2,
    name: "12.03.2025. Позив за прву седницу скупштине Стамбене заједнице у 2025. години",
    document: Poziv_na_prvu_sednicu_skupstine_stanara_17_03_2025,
  },
  {
    id: 3,
    name: "17.03.2025. Записник са прве седнице скупштине Стамбене заједнице у 2025. години",
    document: Zapisnik_sa_I_skupstine_SZ_17_03_2025,
  },
  {
    id: 4,
    name: "18.03.2025. Позив за прву поновљену седницу скупштине Стамбене заједнице у 2025. години",
    document: Poziv_na_prvu_ponovljenu_sednicu_skupstine_stanara_24_03_2025,
  },
];
