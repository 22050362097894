import { memo } from "react";
import classes from "./InformationList.module.css";

import InformationFragment from "./InformationFragment";

const InformationList = memo((props) => {
  const { informations } = props;

  const reversedInfoList = [...informations].reverse();

  const infoList = reversedInfoList.map((info) => (
    <InformationFragment
      key={info.id}
      id={info.id}
      title={info.title}
      date={info.date}
    />
  ));
  return <ul className={classes.list}>{infoList}</ul>;
});

export default InformationList;
