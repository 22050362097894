import { routes } from "../routes/routes";
import Obavestenje_za_remont_malog_lifta_27 from "../assets/Obavestenje_za_remont_malog_lifta_27.12.2024.pdf";

// linkovi se u text unose u sledecem formatu: <link to='putanja linka'>rec koja se linkuje</link>
export const informations = [
  {
    id: 1,
    title: "Нове накнаде за одржавање зграде",
    text: "Сходно одлукама Скупштине стамбене заједнице почев од 01. фебруара 2024. године мењају се висине месечних накнада за одржавање зграде, и износе: <br>- Накнада за одржавање зграде:  300,00 динара: <br>-  Накнада за управљање зградом: 350,00 динара; <br>- Накнада за редовно одржавање лифтова: 300,00 динара; <br>- Средства за потребе ремонта малог лифта: 1.500,00 динара.",
    date: "31.01.2024.",
  },
  {
    id: 2,
    title: "Реконструкција малог лифта",
    text: `<link to='${Obavestenje_za_remont_malog_lifta_27}'>Реконструкција малог лифта</link> почиње после празника и трајаће две недеље. За то време можете користити велики лифт. Уговор о реконструкцији можете погледати на страници: <link to='/${routes.documents}'>Документа</link>.`,
    date: "27.12.2024.",
  },
  {
    id: 3,
    title: "Позив за прву седницу Скупштине станара у 2025. години",
    text: `Поштоване комшије, <br> Обавештавам Вас да ће се састанак Скупштине Стамбене заједнице Јурија Гагарина 122 одржати у <b>понедељак 17.03.2025.</b> године са почетком у <b>18 часова</b> који ће се одржати у ходнику приземља зграде. <br> Званичан позив, као и предложени дневни ред можете погледати на страници: <link to='/${routes.assembly}'>Скупштина</link>.`,
    date: "12.03.2025.",
  },
  {
    id: 4,
    title: "Измена термина одржавања Скупштине станара",
    text: `Поштоване комшије, <br> Обавештавам Вас да се <link to='${require("../assets/Izmena_termina_skupstine_17_03_2025.jpg")}'>термин одржавања седнице Скупштине Стамбене заједнице</link> koja ће се одржати <b>17.03.2025.</b> године помера са 18 часова на <b>19 часова.</b>`,
    date: "16.03.2025.",
  },
  {
    id: 5,
    title: "Позив за прву поновљену седницу Скупштине станара у 2025. години",
    text: `Поштоване комшије, <br> Обавештавам Вас да ће се поновљени састанак Скупштине Стамбене заједнице Јурија Гагарина 122 одржати у <b>понедељак 24.03.2025.</b> године са почетком у <b>19 часова</b> који ће се одржати у ходнику приземља зграде. <br> Званичан позив, као и предложени дневни ред можете погледати на страници: <link to='/${routes.assembly}'>Скупштина</link>.`,
    date: "18.03.2025.",
  },
  {
    id: 6,
    title: "ВАЖНО: Планирано искључење струје за 28.03.2025.",
    text: "Поштоване комшије, <br>Планирано је искључење струје за петак 28.03.2025. од 08:30 до 14:30 часова за Нови Београд, улица Јурија Гагарина за бројеве улаза: 78-94; 112-122; 140-150.",
    date: "27.03.2025.",
  },
];
