import { Fragment, useCallback } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import classes from "./MobileNavBar.module.css";
import { navLinks } from "../../constants/navLinks";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const Sidebar = (props) => {
  const closeSidebarHandler = useCallback(() => {
    props.onClose();
  }, [props]);

  return (
    <nav className={classes["mobile-navigation"]}>
      <div className={classes.close}>
        <button onClick={closeSidebarHandler}></button>
      </div>
      <ul>
        {navLinks.map((link) => (
          <li key={link.id}>
            <Link onClick={closeSidebarHandler} to={link.url}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const portalElement = document.getElementById("overlays");

const MobileNavBar = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onHideSidebar} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <Sidebar onClose={props.onHideSidebar} />,
        portalElement
      )}
    </Fragment>
  );
};

export default MobileNavBar;
