import classes from "./AssemblyContent.module.css";

import MotionCard from "../UI/Card";
import AssemblyList from "./AssemblyList";

const AssemblyContent = () => {
  return (
    <MotionCard
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className={classes.card}
    >
      <AssemblyList />
    </MotionCard>
  );
};

export default AssemblyContent;
