import { Link } from "react-router-dom";
import { documentsList } from "../../constants/documentsList";
import classes from "./DocumentList.module.css";

const DocumentList = () => {
  const reversedDocumentsList = [...documentsList].reverse();

  const linkList = reversedDocumentsList.map((link) => (
    <li key={link.id} className={classes["list-item"]}>
      <Link to={link.document} target="_blank" rel="noopener noreferrer">
        {link.name}
      </Link>
    </li>
  ));

  return <ul className={classes.ul}>{linkList}</ul>;
};

export default DocumentList;
